import { LocalOffer } from '@mui/icons-material';
import { Box, Grid, Theme, Typography } from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { CategoryType } from '@wls-solucoes/lets-eat-types';
import React from 'react';
import { DiscountItem } from '../../CatalogItens';
import CatalogIten from '../CatalogIten';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '29px',
  },
  description: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '20px',
    color: grey[600],
    marginBottom: 16,
    marginLeft: 10,
  },
  container: {
    alignItems: 'flex-end',
    flexWrap: 'nowrap',
    overflowX: 'auto',
    paddingLeft: 10,
    paddingRight: 10,
    width: '100vw',

    msOverflowStyle: 'none',
    scrollbarWidth: 'none',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  gridItem: {
    minWidth: '75vw',

    '& + &': {
      marginLeft: 8,
    },
  },
}));

type PromotionsProps = {
  items: DiscountItem[];
  handleSelectItem: (itemGuid: string, categoryType?: CategoryType) => void;
};

const Promotions: React.FC<PromotionsProps> = ({ items, handleSelectItem }) => {
  const classes = useStyles();

  return (
    <Box
      style={{ padding: '16px 0 24px 0', borderBottom: '1px solid #dedede' }}
    >
      <Box style={{ display: 'flex', marginBottom: 8, marginLeft: 10 }}>
        <Box
          data-testid="category-promotion-indicator"
          style={{
            background: 'rgba(233, 245, 236, 0.75)',
            padding: 4,
            marginRight: 8,
            borderRadius: 4,
            maxHeight: 28,
          }}
        >
          <LocalOffer
            style={{
              color: green[600],
              fontSize: 20,
            }}
          />
        </Box>
        <Typography className={classes.title}>Destaques</Typography>
      </Box>
      <Typography className={classes.description}>
        Produtos em promoção
      </Typography>
      <Grid container className={classes.container}>
        {items.map(({ product, catalogItemGuid, catalogItemType }) => (
          <Grid item key={product.guid} className={classes.gridItem}>
            <CatalogIten
              item={product}
              categoryGuid={catalogItemGuid}
              handleSelectItem={(guid) =>
                handleSelectItem(guid, catalogItemType)
              }
              isPromotion
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Promotions;
