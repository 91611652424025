import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Hidden,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { signInWithPopup } from 'firebase/auth';
import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import ColoredGoogleIcon from '../../../../public/svg/ColoredGoogleIcon';
import { auth } from '../../../firebase/auth';
import { googleAuthProvider } from '../../../firebase/providers';
import { SocialSignIn } from '../../../helpers/utils';
import TicketPercentOutline from '../../../modules/assets/TicketPercentOutline';
import { setCouponAwaitingAuth } from '../../../redux/reducers/discountCode/reducer';

const DialogPaper = styled(Paper)(({ theme }) => ({
  width: 500,
  margin: '8px !important',
  overflowX: 'hidden',
  overflowY: 'hidden !important' as any,
  borderRadius: 8,

  '&::before': {
    content: '""',
    position: 'absolute',
    top: -60,
    right: -210,
    width: 490,
    height: 400,
    transform: 'rotate(38deg)',
    borderRadius: '50%',
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    zIndex: 0,

    [theme.breakpoints.down('sm')]: {
      right: -395,
    },
  },

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: -260,
    left: -275,
    width: 490,
    height: 400,
    transform: 'rotate(85deg)',
    borderRadius: '50%',
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    zIndex: 0,
  },

  '& .MuiDialogContent-root': {
    padding: '48px 16px 8px',
    zIndex: 1,
  },

  '& .MuiDialogActions-root': {
    flexDirection: 'column',
    padding: '24px 16px 24px',
    zIndex: 1,
    gap: 8,

    button: {
      padding: '8px 24px',
    },
  },
}));

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 2,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 700,
  textAlign: 'center',

  span: {
    color: theme.palette.primary.main,
  },
}));

const IllustrationCard = styled(Stack)(({ theme }) => ({
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'space-between',
  minHeight: 140,
  margin: '16px 8px 0 0',
  borderRadius: 20,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  boxShadow: `8px 8px 0 ${alpha(theme.palette.primary.main, 0.25)}`,

  span: {
    width: 'min-content',
    fontSize: '1.125rem',
    textTransform: 'uppercase',
    fontWeight: 700,
    color: theme.palette.primary.main,
    backgroundColor: '#fff',
    padding: '6.5px 16px',
    borderRadius: 8,
  },

  [theme.breakpoints.down('sm')]: {
    minHeight: 105,
    margin: '16px 5px 0 0',
    boxShadow: `5px 5px 0 ${alpha(theme.palette.primary.main, 0.25)}`,

    span: {
      fontSize: '.75rem',
      padding: '0 8px',
    },
  },

  img: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
}));

interface CouponWithRequiredAuthAlertProps {
  open: boolean;
  onClose: () => void;
  closeAll: () => void;
}

const CouponWithRequiredAuthAlert: React.FC<
  CouponWithRequiredAuthAlertProps
> = ({ open, onClose, closeAll }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { push } = useRouter();

  const isSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleLogin = () => {
    signInWithPopup(auth, googleAuthProvider)
      .then((userCredentials) => {
        userCredentials.user.getIdToken().then(async (token) => {
          const userExists = await SocialSignIn(token, userCredentials);
          if (userExists) {
            closeAll();
            push('/');
          } else {
            const { email, displayName: name } = userCredentials.user;
            closeAll();
            push(
              {
                pathname: '/login',
                query: { name, email },
              },
              '/login'
            );
          }
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const handleClose = () => {
    dispatch(setCouponAwaitingAuth(undefined));
    onClose();
  };

  return (
    <Dialog open={open} PaperComponent={DialogPaper} onClose={handleClose}>
      <Tooltip arrow title="Fechar">
        <CloseButton onClick={handleClose}>
          <Close />
        </CloseButton>
      </Tooltip>

      <DialogContent>
        <Title variant="h6">
          CUPOM DE <span>DESCONTO</span>
        </Title>

        <Typography
          style={{
            fontSize: !isSm ? '1.125rem' : undefined,
            fontWeight: 400,
            textAlign: 'center',
          }}
        >
          Para <strong>usar o cupom e ganhar desconto</strong> no pedido{' '}
          <strong>faça login em sua conta</strong> no cardápio.{' '}
          <span role="img" aria-label="rosto com olhar maravilhado">
            🤩
          </span>
        </Typography>

        <IllustrationCard>
          <Box padding={isSm ? '16px 105px 16px 24px' : '24px 0 24px 32px'}>
            <Stack
              mb={isSm ? 1 : 2}
              direction="row"
              alignItems="center"
              gap={1}
            >
              <span>cupom:</span>

              <TicketPercentOutline style={{ fontSize: isSm ? 20 : 32 }} />
            </Stack>

            <Typography
              style={{
                fontSize: isSm ? '1.125rem' : '1.25rem',
                fontWeight: 700,
              }}
            >
              DESCONTO NO PEDIDO!
            </Typography>
          </Box>

          <Hidden smDown>
            <img src="/svg/woman-illustration.svg" alt="Ilustração" />
          </Hidden>

          <Hidden smUp>
            <img src="/svg/woman-illustration-mobile.svg" alt="Ilustração" />
          </Hidden>
        </IllustrationCard>
      </DialogContent>

      <DialogActions disableSpacing>
        <Button
          fullWidth
          disableElevation
          variant="contained"
          onClick={() => {
            closeAll();
            push('/login');
          }}
        >
          fazer login
        </Button>

        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={handleLogin}
          startIcon={<ColoredGoogleIcon />}
        >
          continuar com google
        </Button>

        <Button fullWidth onClick={handleClose}>
          continuar sem cupom
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CouponWithRequiredAuthAlert;
