import { Box, Grow, Theme, Typography, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import SpeechBubbleTriangle from '../../../../modules/assets/SpeechBubbleTriangle';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 200,
    position: 'fixed',
    right: 180,
    top: 90,
    maxWidth: 200,
    backgroundColor: theme.palette.primary.main,
    padding: 10,
    borderRadius: 4,
    zIndex: 10,

    [theme.breakpoints.down('sm')]: {
      position: 'fixed',
      right: 'calc(50% - 100px)',
      bottom: 140,
      top: 'auto',
    },
  },

  triangleContainer: {
    position: 'absolute',
    right: 1,
    top: -19,
    transform: 'rotate(180deg)',

    [theme.breakpoints.down('sm')]: {
      right: 'calc(50% - 10px)',
      top: 'unset',
      bottom: -19,
      transform: 'rotate(0deg)',
    },
  },
}));

interface CartWithItemsSpeechBubbleProps {
  show: boolean;
}

const CartWithItemsSpeechBubble: React.FC<CartWithItemsSpeechBubbleProps> = ({
  show,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grow in={show} mountOnEnter unmountOnExit>
      <Box className={classes.root}>
        <Typography style={{ color: 'white', textAlign: 'center' }}>
          Um item foi adicionado ao seu carrinho{' '}
          <span role="img" aria-label="rosto com olho piscando">
            😉
          </span>
        </Typography>

        <Box className={classes.triangleContainer}>
          <SpeechBubbleTriangle color={theme.palette.primary.main} />
        </Box>
      </Box>
    </Grow>
  );
};

export default CartWithItemsSpeechBubble;
