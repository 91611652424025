import { Box, Skeleton, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    gap: 8,
    color: '#808080',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  textContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '.875rem',
      textAlign: 'center',
    },
  },
}));

interface BasicInfoItemProps {
  isLoading?: boolean;
  icon: JSX.Element;
  primaryText: string;
  secondaryText: string;
  style?: React.CSSProperties;
}

const BasicInfoItem: React.FC<BasicInfoItemProps> = ({
  isLoading,
  icon,
  primaryText,
  secondaryText,
  style,
}) => {
  const classes = useStyles();

  return (
    <Box
      style={style}
      display="flex"
      alignItems="center"
      className={classes.root}
    >
      {isLoading ? (
        <Skeleton variant="circular" width={24} height={24} />
      ) : (
        icon
      )}

      <Box className={classes.textContainer}>
        {isLoading ? (
          <>
            <Skeleton variant="text" width={85} />
            <Skeleton variant="text" width={105} />
          </>
        ) : (
          <>
            <Typography className={classes.text} style={{ lineHeight: 1.25 }}>
              {primaryText}
            </Typography>
            <Typography className={classes.text}>{secondaryText}</Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default BasicInfoItem;
