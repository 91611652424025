import { Box } from '@mui/system';
import React from 'react';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
  disableDefaultMargin?: boolean;
}

const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  disableDefaultMargin = false,
  ...other
}) => {
  return (
    <Box
      mt={!disableDefaultMargin ? 4 : undefined}
      role="tabpanel"
      hidden={value !== index}
      {...other}
    >
      {value === index && <>{children}</>}
    </Box>
  );
};

export default TabPanel;
