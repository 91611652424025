import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../redux/types';
import { PaymentType } from '../../../../../../../../shared/models';
import PaymentMethodSection from './components/PaymentMethodSection';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    '& + &': {
      marginTop: 24,
    },
  },
}));

const PaymentInfoTab: React.FC = () => {
  const classes = useStyles();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const acceptCash = establishment?.paymentSettings.acceptCash;
  const acceptCredit = establishment?.paymentSettings.acceptCredit;
  const acceptDebit = establishment?.paymentSettings.acceptDebit;
  const acceptPix = establishment?.paymentSettings.acceptPix;
  const acceptVoucher = establishment?.paymentSettings.acceptVoucher;
  const acceptMercadoPago = establishment?.paymentSettings.acceptMercadoPago;

  const creditCardFlags = establishment?.paymentSettings.creditCardFlags;
  const debitCardFlags = establishment?.paymentSettings.debitCardFlags;
  const voucherCardFlags = establishment?.paymentSettings.voucherCardFlags;

  return (
    <Grid data-testid="payment-info-container" container>
      {acceptCash && (
        <Grid item xs={12} className={classes.sectionContainer}>
          <PaymentMethodSection paymentType={PaymentType.cash} />
        </Grid>
      )}

      {acceptCredit && (
        <Grid item xs={12} className={classes.sectionContainer}>
          <PaymentMethodSection
            paymentType={PaymentType.credit}
            flags={creditCardFlags}
          />
        </Grid>
      )}

      {acceptDebit && (
        <Grid item xs={12} className={classes.sectionContainer}>
          <PaymentMethodSection
            paymentType={PaymentType.debit}
            flags={debitCardFlags}
          />
        </Grid>
      )}

      {acceptPix && (
        <Grid item xs={12} className={classes.sectionContainer}>
          <PaymentMethodSection paymentType={PaymentType.pix} />
        </Grid>
      )}

      {acceptVoucher && (
        <Grid item xs={12} className={classes.sectionContainer}>
          <PaymentMethodSection
            paymentType={PaymentType.voucher}
            flags={voucherCardFlags}
          />
        </Grid>
      )}

      {acceptMercadoPago && (
        <Grid item xs={12} className={classes.sectionContainer}>
          <PaymentMethodSection paymentType={PaymentType.mercadoPago} />
        </Grid>
      )}
    </Grid>
  );
};

export default PaymentInfoTab;
