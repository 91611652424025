import { faker } from '@faker-js/faker';
import {
  InfoOutlined,
  LocalMall,
  Room,
  ShoppingCart,
  SportsMotorsports,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  Hidden,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import CouponIcon from '../../../../../public/svg/CouponIcon';
import { getUnityOfTime } from '../../../../helpers/utils/getUnityOfTime';
import { toCurrencyValue } from '../../../../helpers/utils/numberFormat';
import { RootState } from '../../../../redux/types';
import { DiscountValueType, UnityOfTime } from '../../../../shared/models';
import {
  LoyaltyPlan,
  LoyaltyPlanStatus,
} from '../../../../shared/models/loyaltyPlan';
import LoyaltyCard from '../../../LoyaltyContainer/components/LoyaltyCard';
import LoyaltyInformation from '../../../LoyaltyContainer/components/LoyaltyCard/components/LoyaltyInformation';
import LoyaltyInfoDialog from '../../../LoyaltyContainer/components/LoyaltyInfoDialog';
import BasicInfoItem from './components/BasicInfoItem';
import EstablishmentAvatar from './components/EstablishmentAvatar';
import EstablishmentInfoDrawer from './components/EstablishmentInfoDrawer';
import OpenCloseIndicator from './components/OpenCloseIndicator';
import { DiscountTarget } from '@wls-solucoes/lets-eat-types';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    rowGap: '1rem',
    marginTop: 32,
    paddingBottom: 32,
    borderBottom: `1px solid ${theme.palette.divider}`,

    [theme.breakpoints.down('md')]: {
      marginTop: 64,
      borderBottom: `2px solid ${theme.palette.divider}`,
    },
  },

  basicInfoItemGrid: {
    gap: 24,

    [theme.breakpoints.down('md')]: {
      gap: 16,
      flexDirection: 'column',
      marginBottom: 28,
      flexBasis: '100%',
    },
  },

  couponItemGrid: {
    [theme.breakpoints.down('lg')]: {
      flexBasis: '100%',
      marginTop: 28,
    },
  },

  infoButtonMobile: {
    textTransform: 'none',
    flexDirection: 'column',

    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
  },
}));

const EstablishmentBasicInfoSection: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const { orderCoupon } = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const { isLoading, loyaltyPlan, myLoyaltyPlanState } = useSelector(
    (state: RootState) => state.loyaltyPlanReducer
  );

  const establishmentAddress = establishment?.baseInfo.address;

  const [showEstablishmentInfoDrawer, setShowEstablishmentInfoDrawer] =
    useState(false);

  const [loyaltyCardInfoDialog, setLoyaltyCardInfoDialog] = useState(false);

  const couponText = useMemo(() => {
    if (!orderCoupon) return '';

    const isPercentage =
      orderCoupon.discountValueType === DiscountValueType.percentage;

    const isFeeDiscount =
      orderCoupon.discountTarget === DiscountTarget.deliveryFee;

    let text = 'Desconto de ';

    text += isPercentage
      ? `${orderCoupon.discount}%`
      : toCurrencyValue(orderCoupon.discount ?? 0);

    text += ' no ';

    text += isFeeDiscount ? 'frete' : 'pedido';

    if (orderCoupon.minimumOrderValue) {
      text += ` para pedido <b>mínimo de ${toCurrencyValue(
        orderCoupon.minimumOrderValue
      )}</b>`;
    }
    return text;
  }, [orderCoupon]);
  const handleOpenEstablishmentInfoDrawer = (): void => {
    setShowEstablishmentInfoDrawer(true);
  };

  const handleCloseEstablishmentInfoDrawer = (): void => {
    setShowEstablishmentInfoDrawer(false);
  };

  const handleOpenLoyaltyCardInfoDialog = (): void => {
    setLoyaltyCardInfoDialog(true);
  };

  const handleCloseLoyaltyCardInfoDialog = (): void => {
    setLoyaltyCardInfoDialog(false);
  };

  return (
    <>
      {loyaltyCardInfoDialog && loyaltyPlan && !isMobile && (
        <LoyaltyInfoDialog
          open
          data={loyaltyPlan}
          onClose={handleCloseLoyaltyCardInfoDialog}
        />
      )}
      <EstablishmentInfoDrawer
        open={showEstablishmentInfoDrawer}
        onClose={handleCloseEstablishmentInfoDrawer}
      />

      <Box
        height={isMobile && !establishment?.backgroundPath ? 75 : undefined}
        style={{ position: 'relative' }}
      >
        {establishment?.backgroundPath && (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            height={!isMobile ? 192 : 120}
            width="100%"
            src={establishment?.backgroundPath ?? ''}
            alt="Capa do estabelecimento"
            style={{ objectFit: 'cover' }}
          />
        )}

        <Hidden mdUp>
          <Box
            style={{
              position: 'absolute',
              left: 'calc(50% - 40px)',
              bottom: -40,
            }}
          >
            <EstablishmentAvatar
              establishmentName={establishment?.name ?? ''}
              path={establishment?.thumbLogoPath}
            />
          </Box>
        </Hidden>
      </Box>

      <Grid
        container
        alignItems={!isMobile ? 'center' : 'flex-start'}
        justifyContent={!isMobile ? 'space-between' : 'space-evenly'}
        className={classes.root}
      >
        <Grid
          item
          className={classes.basicInfoItemGrid}
          display="flex"
          alignItems="center"
        >
          <Hidden mdDown>
            <EstablishmentAvatar
              establishmentName={establishment?.name ?? ''}
              path={establishment?.thumbLogoPath}
            />
          </Hidden>

          <Box>
            <Box
              display="flex"
              alignItems="center"
              justifyContent={isMobile ? 'center' : 'space-between'}
            >
              <Typography variant="h5" style={{ fontWeight: 600 }}>
                {establishment?.name}
              </Typography>

              <Hidden mdDown>
                <Button
                  data-testid="info-button"
                  color="primary"
                  startIcon={<InfoOutlined />}
                  style={{ textTransform: 'none', marginLeft: 8 }}
                  onClick={handleOpenEstablishmentInfoDrawer}
                >
                  Informações
                </Button>
              </Hidden>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              style={{ gap: 8, color: '#808080', marginTop: 10 }}
            >
              <Room />
              <Typography
                style={{ fontSize: isMobile ? '.875rem' : undefined }}
              >
                {`${establishmentAddress?.neighborhood}, ${establishmentAddress?.city.name} - ${establishmentAddress?.city.state.uf}`}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item>
          {establishment?.deliverySettings?.enableDelivery ? (
            <BasicInfoItem
              icon={<SportsMotorsports />}
              primaryText={`${
                establishment?.deliverySettings.deliveryAverageTime?.from ?? 0
              } - ${
                establishment?.deliverySettings.deliveryAverageTime?.until ?? 0
              }`}
              secondaryText={getUnityOfTime(
                establishment?.deliverySettings.deliveryAverageTime
                  ?.unityOfTime ?? ('' as UnityOfTime)
              )}
            />
          ) : (
            <BasicInfoItem
              icon={<LocalMall />}
              primaryText={`${
                establishment?.deliverySettings?.takeOutAverageTime?.from ?? 0
              } - ${
                establishment?.deliverySettings?.takeOutAverageTime?.until ?? 0
              }`}
              secondaryText={getUnityOfTime(
                establishment?.deliverySettings?.takeOutAverageTime
                  ?.unityOfTime ?? ('' as UnityOfTime)
              )}
            />
          )}
        </Grid>

        <Grid item>
          <OpenCloseIndicator />
        </Grid>

        <Grid item>
          <BasicInfoItem
            icon={<ShoppingCart />}
            primaryText="Pedido mínimo"
            secondaryText={toCurrencyValue(
              establishment?.deliverySettings?.minDeliveryAmount ?? 0
            )}
            style={{ width: isMobile ? 'min-content' : undefined }}
          />
        </Grid>
        <Hidden mdDown>
          {loyaltyPlan?.status === LoyaltyPlanStatus.activated || isLoading ? (
            <Grid item>
              <LoyaltyInformation
                data={loyaltyPlan as LoyaltyPlan}
                currentPoints={myLoyaltyPlanState?.currentPoints}
                singleComponentVariant
                onClick={handleOpenLoyaltyCardInfoDialog}
                isLoading={isLoading}
              />
            </Grid>
          ) : (
            <></>
          )}
        </Hidden>

        <Hidden mdUp>
          <Grid item>
            <Button
              color="primary"
              className={classes.infoButtonMobile}
              startIcon={<InfoOutlined />}
              onClick={handleOpenEstablishmentInfoDrawer}
            >
              Info
            </Button>
          </Grid>
        </Hidden>

        {orderCoupon && (
          <Grid
            item
            display="flex"
            justifyContent="center"
            className={classes.couponItemGrid}
          >
            <Box
              style={{
                maxWidth: 320,
                background: '#ECF6F0',
                padding: '4px 8px',
                display: 'flex',
                alignItems: 'center',
                borderRadius: 4,
              }}
            >
              <CouponIcon style={{ color: '#3DA865' }} />
              <Box style={{ marginLeft: 8 }}>
                <Typography
                  style={{ color: '#3DA865' }}
                  dangerouslySetInnerHTML={{
                    __html: couponText,
                  }}
                />
              </Box>
            </Box>
          </Grid>
        )}
        <Hidden mdUp>
          {(loyaltyPlan?.status === LoyaltyPlanStatus.activated ||
            isLoading) && (
            <Box display="flex" justifyContent={'center'} width="100%">
              <LoyaltyInformation
                data={loyaltyPlan as LoyaltyPlan}
                currentPoints={myLoyaltyPlanState?.currentPoints}
                singleComponentVariant
                onClick={handleOpenLoyaltyCardInfoDialog}
                isLoading={isLoading}
              />
            </Box>
          )}
        </Hidden>
      </Grid>
    </>
  );
};

export default EstablishmentBasicInfoSection;
