import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Tabs, Tab, Tooltip, Menu, MenuItem, Box, Grid } from '@mui/material';
import { grey } from '@mui/material/colors';
import { FormikProps } from 'formik';
import { initialValuesProps } from '../../Catalog';
import { KeyboardArrowDown } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  paperRoot: {
    boxShadow:
      '0px 2px 4px rgba(0, 0, 0, 0.14), 0px 4px 5px rgba(0, 0, 0, 0.12), 0px 1px 10px rgba(0, 0, 0, 0.2);',
  },
  root: {
    borderBottom: `2px solid ${grey[300]}`,
    maxWidth: 150,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

type FiltersProps = {
  formik: FormikProps<initialValuesProps>;
};

const Filters: React.FC<FiltersProps> = ({ formik }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const { values, setFieldValue } = formik;

  const arraySlice = [
    values.catalogItens.slice(0, 4),
    values.catalogItens.slice(4, values.catalogItens.length),
  ];

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleOpen = (e: any): void => {
    setAnchorEl(e);
  };

  return (
    <Grid item>
      <Tabs value={values.filtersIndex > 4 ? 4 : values.filtersIndex}>
        {arraySlice[0].map((item, i) => (
          <Tooltip key={i} title={item.name} arrow>
            <Tab
              onClick={() =>
                setFieldValue(
                  'filtersIndex',
                  values.filtersIndex === i ? false : i
                )
              }
              label={
                item.name.length > 13
                  ? `${item.name.substring(0, 10)}...`
                  : item.name
              }
              className={classes.root}
            />
          </Tooltip>
        ))}
        {arraySlice[1].length > 0 && (
          <Tab
            label={
              <Box style={{ alignItems: 'center', display: 'flex' }}>
                Mais
                <KeyboardArrowDown />
              </Box>
            }
            onClick={(e) => handleOpen(e.currentTarget)}
            className={classes.root}
          />
        )}
      </Tabs>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.paperRoot }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {arraySlice[1].map((item, i) => (
          <MenuItem
            key={item.guid}
            onClick={() => {
              setFieldValue(
                'filtersIndex',
                values.filtersIndex === i + arraySlice[0].length
                  ? false
                  : i + arraySlice[0].length
              );
              handleClose();
            }}
          >
            {item.name}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default Filters;
