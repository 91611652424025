import { Add, Remove } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
  CatalogCategory,
  CategoryType,
  ItemDisplayModeInCategory,
} from '@wls-solucoes/lets-eat-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeExpandedCategory } from '../../../../../../../../redux/reducers/catalog/reducer';
import { RootState } from '../../../../../../../../redux/types';
import CatalogIten from '../CatalogIten';

const useStyles = makeStyles(() => ({
  categoryName: {
    display: '-webkit-box',
    overflow: 'hidden',
    fontSize: '1.125rem',
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },

  productList: {
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 0',
  },
}));

interface OrderCategoryAccordionProps {
  category: CatalogCategory;
  handleSelectItem: (itemGuid: string, categoryType?: CategoryType) => void;
}

const OrderCategoryAccordion: React.FC<OrderCategoryAccordionProps> = ({
  category,
  handleSelectItem,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { search } = useSelector((state: RootState) => state.searchReducer);
  const { catalog } = useSelector((state: RootState) => state.catalogReducer);
  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const expanded =
    catalog?.find((c) => c.guid === category.guid)?.expanded ?? false;

  const [lastSearchValue, setLastSearchValue] = useState(search);

  useEffect(() => {
    if (search && search !== lastSearchValue) {
      dispatch(changeExpandedCategory({ guid: category.guid, value: true }));
    } else {
      dispatch(
        changeExpandedCategory({
          guid: category.guid,
          value:
            establishment?.webSettings.itemDisplayModeInCategory ===
              ItemDisplayModeInCategory.open ?? false,
        })
      );
    }

    setLastSearchValue(search);
  }, [search]);

  return (
    <Box>
      <Accordion
        data-testid="category-accordion"
        square
        elevation={0}
        variant="elevation"
        expanded={expanded}
        onChange={() =>
          dispatch(
            changeExpandedCategory({ guid: category.guid, value: !expanded })
          )
        }
      >
        <AccordionSummary
          expandIcon={
            expanded ? <Remove color="primary" /> : <Add color="primary" />
          }
          style={{ padding: '0 8px' }}
        >
          <Box>
            <Typography
              className={classes.categoryName}
              style={{
                opacity: !category.isOnOpeningHours ? 0.5 : undefined,
                textDecoration: !category.isOnOpeningHours
                  ? 'line-through'
                  : undefined,
              }}
            >
              {category.name}
            </Typography>

            <Typography
              data-testid="category-description"
              style={{
                fontSize: '.875rem',
                color: '#808080',
                wordBreak: 'break-word',
                opacity: !category.isOnOpeningHours ? 0.5 : undefined,
                textDecoration: !category.isOnOpeningHours
                  ? 'line-through'
                  : undefined,
              }}
            >
              {category.description}
            </Typography>
          </Box>
        </AccordionSummary>

        <AccordionDetails className={classes.productList}>
          {expanded &&
            category.items.map((item, index) => (
              <Box data-testid="item-container" key={item.guid}>
                <CatalogIten
                  isOnOpeningHours={category.isOnOpeningHours}
                  item={item}
                  categoryGuid={category.guid ?? ''}
                  handleSelectItem={(guid) =>
                    handleSelectItem(guid, category.categoryType)
                  }
                />

                {index !== category.items.length - 1 && <Divider />}
              </Box>
            ))}
        </AccordionDetails>
      </Accordion>

      <Divider />
    </Box>
  );
};

export default OrderCategoryAccordion;
