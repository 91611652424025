import { AccessAlarm } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTimeZone } from '../../../../../../../../helpers/hooks';
import { getOpeningHours } from '../../../../../../../../helpers/utils';
import { RootState } from '../../../../../../../../redux/types';
import { DayOfTheWeek } from '../../../../../../../../shared/models';

const useStyles = makeStyles((theme) => ({
  hourContainer: {
    '& + &': {
      marginTop: 24,
    },
  },

  bold: {
    '& .MuiTypography-root': {
      fontWeight: 700,
    },
  },
}));

interface Day {
  translated: string;
  dayOfWeek: DayOfTheWeek;
}

const OpeningHoursTab: React.FC = () => {
  const classes = useStyles();

  const { correctCurrentTime } = useTimeZone();

  const { openingHoursSettings } = useSelector(
    (state: RootState) => state.openingHoursSettingsReducer
  );

  const days: Day[] = [
    {
      translated: 'Segunda-feira',
      dayOfWeek: DayOfTheWeek.monday,
    },
    {
      translated: 'Terça-feira',
      dayOfWeek: DayOfTheWeek.tuesday,
    },
    {
      translated: 'Quarta-feira',
      dayOfWeek: DayOfTheWeek.wednesday,
    },
    {
      translated: 'Quinta-feira',
      dayOfWeek: DayOfTheWeek.thursday,
    },
    {
      translated: 'Sexta-feira',
      dayOfWeek: DayOfTheWeek.friday,
    },
    {
      translated: 'Sábado',
      dayOfWeek: DayOfTheWeek.saturday,
    },
    {
      translated: 'Domingo',
      dayOfWeek: DayOfTheWeek.sunday,
    },
  ];

  return (
    <Grid container>
      {days.map((day) => {
        const isToday =
          correctCurrentTime().format('dddd') === day.translated.toLowerCase();

        return (
          <Grid
            item
            data-testid={`${day.dayOfWeek}-container`}
            xs={12}
            display="flex"
            justifyContent="space-between"
            key={day.translated}
            className={classes.hourContainer}
          >
            <Box
              display="flex"
              className={isToday ? classes.bold : undefined}
              style={{ gap: 8 }}
            >
              {isToday && <AccessAlarm />}

              <Typography>{day.translated}</Typography>
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="end"
              className={isToday ? classes.bold : undefined}
              style={{ gap: 8, maxWidth: '75%' }}
            >
              {getOpeningHours(
                day.dayOfWeek,
                openingHoursSettings?.openingHours
              )}
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default OpeningHoursTab;
