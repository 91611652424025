import React, { memo } from 'react';
import { Grid, Skeleton } from '@mui/material';

const RandomNumberOfItens: React.FC = () => {
  return (
    <>
      {Array.from(Array(Math.floor(Math.random() * 4) + 6).keys()).map(
        (_, key) => (
          <Grid item sm={6} md={4} key={key}>
            <Skeleton
              variant="rectangular"
              width="100%"
              height={173}
              style={{ borderRadius: 8 }}
            />
          </Grid>
        )
      )}
    </>
  );
};

export default memo(RandomNumberOfItens);
