import { Avatar, Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import WhiteLogoAvatar from '../../../../../../../public/svg/WhiteLogoAvatar';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 80,
    height: 80,
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
}));

interface EstablishmentAvatarProps {
  establishmentName: string;
  path?: string;
}

const EstablishmentAvatar: React.FC<EstablishmentAvatarProps> = ({
  establishmentName,
  path,
}) => {
  const classes = useStyles();

  if (!path) {
    return (
      <Box className={classes.root}>
        <WhiteLogoAvatar
          viewBox="0 0 47 35"
          style={{ width: 47, height: 35 }}
        />
      </Box>
    );
  }

  return (
    <Avatar
      variant="circular"
      src={path}
      alt={establishmentName}
      style={{ width: 80, height: 80 }}
    />
  );
};

export default EstablishmentAvatar;
