import { initializeApp } from 'firebase/app';

const firebaseConfig = {
  apiKey: 'AIzaSyA6GFq8vExrStDjzG97XDwUAJAqU3R1bog',
  authDomain: 'auth.compraqui.app',
  databaseURL: 'https://lets-eat-prod-f882d.firebaseio.com',
  projectId: 'lets-eat-prod-f882d',
  storageBucket: 'lets-eat-prod-f882d.appspot.com',
  messagingSenderId: '151496253757',
  appId: '1:151496253757:web:cdd91b2a10bdef8e436554',
  measurementId: 'G-D3K17LSD7G',
};
const app = initializeApp(firebaseConfig);

export { app };
