import { Add, Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { ItemRecommendation } from '../../../../../../../../shared/models';

const useStyles = makeStyles<Theme>((theme) => ({
  noThanks: {
    width: 'max-content',
    fontSize: '.875rem',
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    margin: '24px auto 0 auto',

    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ItemRecommendationDialogProps {
  open: boolean;
  recommendedItem?: ItemRecommendation;
  onIWantClick: () => void;
  onClose: () => void;
}

const ItemRecommendationDialog: React.FC<ItemRecommendationDialogProps> = ({
  open,
  recommendedItem,
  onIWantClick,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        padding="24px 32px"
      >
        <Typography style={{ fontSize: '1.5rem', fontWeight: 600 }}>
          Peça também
        </Typography>

        <Tooltip arrow title="Fechar">
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Tooltip>
      </Box>

      <DialogContent style={{ padding: 0 }}>
        {recommendedItem?.imageUrl && (
          <img
            width="100%"
            height={275}
            src={recommendedItem.imageUrl}
            alt={`Imagem de ${recommendedItem?.itemName ?? ''}`}
            style={{ objectFit: 'contain' }}
          />
        )}

        <Box padding={4}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {recommendedItem?.itemName}
          </Typography>

          <Typography style={{ fontWeight: 400 }}>
            {recommendedItem?.itemDescription}
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions style={{ display: 'block', padding: '0 32px 32px' }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={onIWantClick}
        >
          sim, quero!
        </Button>

        <Typography className={classes.noThanks} onClick={onClose}>
          não, obrigado
        </Typography>
      </DialogActions>
    </Dialog>
  );
};

export default ItemRecommendationDialog;
