import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Card, Typography } from '@mui/material';
import LineItem from '../../../LineItem';
import { LoyaltyPlan } from '../../../../../../shared/models/loyaltyPlan';
import { toCurrencyValue } from '../../../../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '37.5rem',
    margin: '0 auto',
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '1rem',
    lineHeight: '150%',

    color: '#636965',
  },
  card: {
    padding: '1rem',
    margin: '1rem 0 1.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  rulesTitle: {
    fontWeight: 600,
    fontSize: '1rem',
    lineHeight: '24px',
    color: '#191A19',
    marginBottom: '0.75rem',
  },
  ruleContainer: {
    paddingLeft: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  ruleItem: {
    color: '#636965',
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '150%',

    '& + &': {
      marginTop: '1.5rem',
    },
  },
}));

const rules = [
  {
    text: 'O cupom estará disponível para ser usado na próxima compra após conclusão do Programa, podendo ter data de validade ou não;',
  },
  {
    text: 'O cupom não é cumulativo com outros cupons;',
  },
  {
    text: 'Para poder participar do Programa é necessário estar logado no momento do checkout;',
  },
  {
    text: 'Para utilizar o cupom, é necessário estar logado;',
  },
  {
    text: 'Será contabilizado apenas pedidos válidos e finalizados para o programa;',
  },
  {
    text: 'Cada restaurante estipula uma data para completar o Programa, caso não seja cumprida o Programa reiniciará;',
  },
];

type LoyaltyInfoContentProps = {
  data: LoyaltyPlan;
};

const LoyaltyInfoContent: React.FC<LoyaltyInfoContentProps> = ({ data }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root} data-testid="loyalty-info-content">
      <Typography className={classes.subtitle}>
        A cada {data.orderQuantity} pedidos de{' '}
        {toCurrencyValue(data.orderMinValue)}, ganhe um cupom de{' '}
        {toCurrencyValue(data.couponDiscountValue)}
      </Typography>

      <Card variant="outlined" className={classes.card}>
        <LineItem
          legend="Prazo para completar"
          value={data.daysToCompleteProgram}
        />
        <LineItem
          legend="Validade do cupom"
          value={data.couponDeadLineInDays}
        />
      </Card>

      <Typography className={classes.rulesTitle}>Regras:</Typography>
      <Box className={classes.ruleContainer}>
        {rules.map((rule) => (
          <Typography
            key={rule.text}
            className={classes.ruleItem}
            component={'li'}
          >
            {rule.text}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

export default LoyaltyInfoContent;
