import { Close } from '@mui/icons-material';
import {
  Box,
  Drawer,
  IconButton,
  Tab,
  Tabs,
  Theme,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import TabPanel from '../../../../../../shared/components/TabPanel';
import AboutTab from './components/AboutTab';
import OpeningHoursTab from './components/OpeningHoursTab';
import PaymentInfoTab from './components/PaymentInfoTab';

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: 658,
    maxWidth: '100%',
    height: 600,
    left: 'calc(50% - 329px)',
    paddingBottom: 32,

    [theme.breakpoints.down('md')]: {
      height: 'calc(100% - 64px)',
      width: '100%',
      left: 'calc(50% - 50vw)',
      borderRadius: '8px 8px 0 0',
    },

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 0,
    },
  },
}));

interface EstablishmentInfoDrawerProps {
  open: boolean;
  onClose: () => void;
}

const EstablishmentInfoDrawer: React.FC<EstablishmentInfoDrawerProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentTab, setCurrentTab] = useState(0);

  const handleChangeTab = (event: React.SyntheticEvent, tab: number) => {
    setCurrentTab(tab);
  };

  const handleChangeIndex = (index: number) => {
    setCurrentTab(index);
  };

  return (
    <Drawer
      open={open}
      anchor="bottom"
      onBackdropClick={onClose}
      onClose={onClose}
      PaperProps={{ className: classes.paper }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ padding: !isXs ? '32px 32px 0 32px' : '16px 16px 0 16px' }}
      >
        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          style={{ width: 'min-content', borderBottom: '1px solid #ccc' }}
        >
          <Tab label="sobre" />
          <Tab label="horário" />
          <Tab label="pagamento" />
        </Tabs>

        <Tooltip title="Fechar">
          <IconButton
            data-testid="close-drawer-btn"
            style={{ width: 49 }}
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Tooltip>
      </Box>

      <Box
        style={{
          padding: !isXs ? '0 32px' : '0 16px 16px 32px',
          overflow: 'auto',
        }}
      >
        <TabPanel value={currentTab} index={0}>
          <AboutTab />
        </TabPanel>

        <TabPanel value={currentTab} index={1}>
          <OpeningHoursTab />
        </TabPanel>

        <TabPanel value={currentTab} index={2}>
          <PaymentInfoTab />
        </TabPanel>
      </Box>
    </Drawer>
  );
};

export default EstablishmentInfoDrawer;
