import { LocalOffer } from '@mui/icons-material';
import {
  Box,
  Card,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { CatalogItem } from '@wls-solucoes/lets-eat-types';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Swal from 'sweetalert2';
import { useEventHandler } from '../../../../../../../../helpers/hooks';
import { toCurrencyValue } from '../../../../../../../../helpers/utils';

type useStylesProps = {
  isPromotion?: boolean;
};

const useStyles = makeStyles<Theme, useStylesProps>((theme) => ({
  root: {
    width: '100%',
    flexDirection: 'column',
  },
  card: {
    width: '100%',
    height: 173,
    transition: 'background-color .4s',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}15`,
    },

    '&:active': {
      backgroundColor: `${theme.palette.primary.main}25`,
    },

    [theme.breakpoints.down('sm')]: {
      height: ({ isPromotion }) => (!isPromotion ? 'unset' : undefined),
      borderRadius: ({ isPromotion }) => (isPromotion ? 'auto' : 0),
      border: ({ isPromotion }) => (isPromotion ? 'auto' : 'none'),
    },
  },

  itemInfoContainer: {
    height: 173,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 16,

    [theme.breakpoints.down('sm')]: {
      height: 'unset',
      padding: '16px 8px',
    },
  },

  itemName: {
    fontSize: 16,
    fontWeight: 600,
    marginBottom: 8,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    'line-clamp': 2,
    '-webkit-box-orient': 'vertical',
  },

  itemDescription: {
    fontSize: 14,
    fontWeight: 400,
    color: grey[600],
    marginBottom: 8,
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '-webkit-line-clamp': 3,
    'line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
}));

type OrderItemCardProps = {
  item: CatalogItem;
  isOnOpeningHours?: boolean;
  categoryGuid: string;
  handleSelectItem: (itemGuid: string) => void;
  isPromotion?: boolean;
};

const OrderItemCard: React.FC<OrderItemCardProps> = ({
  item,
  isOnOpeningHours = true,
  categoryGuid,
  handleSelectItem,
  isPromotion,
}) => {
  const classes = useStyles({ isPromotion });
  const theme = useTheme();
  const { eventHandler } = useEventHandler();

  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));

  const onClickItem = (): void => {
    const eventOptions = {
      value: item.price,
      item: {
        item_id: item.guid,
        item_name: item.name,
        item_category: categoryGuid,
      },
    };

    if (isOnOpeningHours) {
      handleSelectItem(item.guid);

      if (isPromotion) {
        eventHandler.selectPromotion(eventOptions.item);
      } else {
        eventHandler.viewItem(eventOptions);
      }
    } else {
      Swal.fire({
        title: 'Ops!',
        text: 'Item não disponível no momento',
        icon: 'warning',
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Card
        data-testid="catalog-item"
        variant="outlined"
        className={classes.card}
        onClick={onClickItem}
      >
        <Box className={classes.itemInfoContainer}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
          >
            <Box style={{ wordBreak: 'break-word' }}>
              <Typography
                data-testid="catalog-item-name"
                className={classes.itemName}
                style={{
                  opacity: !isOnOpeningHours ? 0.5 : undefined,
                  textDecoration: !isOnOpeningHours
                    ? 'line-through'
                    : undefined,
                }}
              >
                {item.name}
              </Typography>

              <Typography
                className={classes.itemDescription}
                style={{
                  textDecoration: !isOnOpeningHours
                    ? 'line-through'
                    : undefined,
                }}
              >
                {item.description}
              </Typography>
            </Box>

            {item.promotionalPrice === undefined ? (
              <>
                {item.price !== 0 && (
                  <Typography
                    data-testid="min-item-price"
                    variant="h6"
                    style={
                      !isOnOpeningHours
                        ? { opacity: 0.5, textDecoration: 'line-through' }
                        : undefined
                    }
                  >
                    {toCurrencyValue(item.price)}
                  </Typography>
                )}
              </>
            ) : (
              <Box
                data-testid="promotion-price-container"
                display="flex"
                alignItems="center"
                style={{ gap: '4px', flexWrap: 'wrap' }}
              >
                <Typography
                  data-testid="min-promo-price"
                  variant="h6"
                  style={{
                    fontWeight: 'bold',
                    color: green[500],
                    opacity: !isOnOpeningHours ? 0.5 : undefined,
                    textDecoration: !isOnOpeningHours
                      ? 'line-through'
                      : undefined,
                  }}
                >
                  {toCurrencyValue(item.promotionalPrice)}
                </Typography>

                <Typography
                  style={{
                    fontSize: '1rem',
                    color: '#ccc',
                    textDecoration: 'line-through',
                  }}
                >
                  {toCurrencyValue(item.price)}
                </Typography>
              </Box>
            )}
          </Box>

          {item.thumbCoverImage !== undefined || item.flavors ? (
            <span
              data-testid="catalog-item-image-container"
              style={{
                minWidth: isMd ? 141 : 74,
                opacity: !isOnOpeningHours ? 0.5 : undefined,
              }}
            >
              <LazyLoadImage
                width={isMd ? 141 : 74}
                height={isMd ? 141 : 74}
                src={
                  item.flavors
                    ? `/images/pizza-flavors-${item.flavors}.png`
                    : item.thumbCoverImage
                }
                alt="Imagem do produto"
                style={{ borderRadius: 8, objectFit: 'cover' }}
              />
            </span>
          ) : null}
        </Box>
      </Card>
      {item.promotionPercentage && (
        <Box
          data-testid="promotion-container"
          display="flex"
          alignItems="center"
          style={{
            width: isXs ? 'fit-content' : '100%',
            backgroundColor: '#E9F5EC',
            padding: !isXs ? 8 : '5px 10px',
            borderRadius: isXs ? 4 : '0 0 4px 4px',
            marginTop: isXs ? 16 : undefined,
          }}
        >
          <LocalOffer
            style={{ color: green[600], marginRight: 5, fontSize: 15 }}
          />
          <Typography
            style={{
              color: green[600],
              fontWeight: 600,
              fontSize: '.875rem',
            }}
          >
            Até {item.promotionPercentage}% de desconto
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default OrderItemCard;
