import { AccessAlarm, AlarmOff, CalendarMonth } from '@mui/icons-material';
import { Box, Skeleton, Theme, Typography } from '@mui/material';
import { green, red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../redux/types';
import { OpenType } from '../../../../../../shared/models';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    gap: 8,
    color: '#808080',

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },

  textContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },

  text: {
    [theme.breakpoints.down('md')]: {
      fontSize: '.875rem',
      textAlign: 'center',
    },
  },
}));

const OpenCloseIndicator: React.FC = () => {
  const classes = useStyles();

  const { openingHoursSettings } = useSelector(
    (state: RootState) => state.openingHoursSettingsReducer
  );

  const showOpenInfo =
    openingHoursSettings?.isOpen &&
    (openingHoursSettings?.openType === OpenType.open ||
      openingHoursSettings?.openType === OpenType.openWithSchedule);

  const showOnlyScheduleInfo =
    (openingHoursSettings?.isOpen &&
      openingHoursSettings?.openType === OpenType.onlySchedule) ||
    (!openingHoursSettings?.isOpen &&
      openingHoursSettings?.openType === OpenType.closedWithSchedule);

  const showClosedInfo =
    !openingHoursSettings?.isOpen &&
    openingHoursSettings?.openType === OpenType.closed;

  return (
    <Box
      style={{
        color: showOpenInfo
          ? green[500]
          : showOnlyScheduleInfo
          ? '#E08600'
          : red[500],
      }}
      display="flex"
      alignItems="center"
      className={classes.root}
    >
      {!openingHoursSettings ? (
        <Skeleton variant="circular" width={24} height={24} />
      ) : (
        <>
          {showOpenInfo && <AccessAlarm />}
          {showOnlyScheduleInfo && <CalendarMonth />}
          {showClosedInfo && <AlarmOff />}
        </>
      )}

      <Box className={classes.textContainer}>
        {!openingHoursSettings ? (
          <>
            <Box data-testid="loading-container">
              <Skeleton variant="text" width={85} />
              <Skeleton variant="text" width={105} />
            </Box>
          </>
        ) : (
          <>
            <Typography className={classes.text} style={{ lineHeight: 1.25 }}>
              {showOpenInfo && 'Aberto'}
              {showOnlyScheduleInfo && 'Aberto para'}
              {showClosedInfo && 'Fechado'}
            </Typography>
            <Typography className={classes.text}>
              {(showOpenInfo || showClosedInfo) && 'para pedidos'}
              {showOnlyScheduleInfo && 'agendamentos'}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default OpenCloseIndicator;
