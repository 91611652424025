import { Close, Search } from '@mui/icons-material';
import {
  debounce,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEventHandler } from '../../../../../../helpers/hooks';
import {
  cleanSearch,
  setSearch,
} from '../../../../../../redux/reducers/search/reducer';
import { RootState } from '../../../../../../redux/types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 300,
  },
  icons: {
    fontSize: 24,
    color: grey[600],
    cursor: 'pointer',
  },
}));

const SearchBar: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { eventHandler } = useEventHandler();

  const { search } = useSelector((state: RootState) => state.searchReducer);

  const searchTriggerDebounce = useCallback(
    debounce((search_term: string) => {
      eventHandler.search(search_term);
    }, 1000),
    []
  );

  const handleSearchText = (searchText: string) => {
    dispatch(setSearch(searchText));
    searchTriggerDebounce(searchText);
  };

  const handleCleanSearch = () => {
    dispatch(cleanSearch());
  };

  return (
    <Grid item style={{ flex: 1, margin: 'auto' }}>
      <TextField
        fullWidth
        onChange={(e) => {
          handleSearchText(e.target.value);
        }}
        className={classes.root}
        value={search}
        size="small"
        variant="outlined"
        placeholder="Pesquisar produtos"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search className={classes.icons} />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <Fade in={search.length > 0}>
                <IconButton
                  className={classes.icons}
                  onClick={handleCleanSearch}
                >
                  <Close />
                </IconButton>
              </Fade>
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

export default SearchBar;
