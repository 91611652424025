import { ShoppingCart } from '@mui/icons-material';
import {
  Badge,
  ButtonBase,
  Grow,
  Paper,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useRouter } from 'next/router';
import React from 'react';
import { useSelector } from 'react-redux';
import { toCurrencyValue } from '../../../../helpers/utils';
import { itemsTotalSelector } from '../../../../redux/reducers/order/selectors';
import { RootState } from '../../../../redux/types';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    minHeight: 60,
    position: 'fixed',
    left: 0,
    bottom: 56,
    width: '100vw',
    zIndex: 2,
    borderRadius: 0,
  },

  content: {
    padding: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
  },

  icon: {
    color: theme.palette.primary.contrastText,
    fontSize: 24,
    marginRight: 8,
  },

  text: {
    textTransform: 'uppercase',
    color: theme.palette.primary.contrastText,
    fontSize: '1rem',
    fontWeight: 500,
  },

  chip: {
    backgroundColor: 'white',
    fontWeight: 600,
    fontSize: '.875rem',
  },
}));

const MobileCartButton: React.FC = () => {
  const classes = useStyles();
  const { push } = useRouter();

  const orderCheckout = useSelector(
    (state: RootState) => state.orderReducer.order
  );

  const total = useSelector(itemsTotalSelector);

  if (orderCheckout === undefined || (orderCheckout.items?.length ?? 0) === 0) {
    return null;
  }

  return (
    <Paper className={classes.root} elevation={0}>
      <ButtonBase
        data-testid="mobile-cart-button"
        style={{ width: '100%', display: 'contents' }}
        onClick={() => push('/cart')}
      >
        <Grow in timeout={500}>
          <div>
            <div className={classes.content}>
              <Badge
                badgeContent={orderCheckout.items?.length ?? 0}
                color="secondary"
              >
                <ShoppingCart className={classes.icon} />
              </Badge>

              <Typography className={classes.text}>
                {`ver carrinho (${orderCheckout.items?.length ?? 0})`}
              </Typography>

              <Typography className={classes.text}>
                {toCurrencyValue(total ?? 0)}
              </Typography>
            </div>
          </div>
        </Grow>
      </ButtonBase>
    </Paper>
  );
};

export default MobileCartButton;
