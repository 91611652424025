import { SvgIcon, SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React from 'react';

const WhiteLogoAvatar: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> = (
  props: any
) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon {...props}>
      <path
        d="M32.6265 34.0556C34.5382 34.0556 36.0878 32.5463 36.0878 30.6846C36.0878 28.8228 34.5382 27.3135 32.6265 27.3135C30.7148 27.3135 29.1651 28.8228 29.1651 30.6846C29.1651 32.5463 30.7148 34.0556 32.6265 34.0556Z"
        fill="white"
      />
      <path
        d="M22.7959 34.0556C24.7075 34.0556 26.2572 32.5463 26.2572 30.6846C26.2572 28.8228 24.7075 27.3135 22.7959 27.3135C20.8842 27.3135 19.3345 28.8228 19.3345 30.6846C19.3345 32.5463 20.8842 34.0556 22.7959 34.0556Z"
        fill="white"
      />
      <path
        d="M45.9398 6.67487C45.6622 6.04226 45.1426 5.55697 44.4806 5.30566C44.1709 5.1878 43.847 5.12887 43.5142 5.12887H27.2894C25.8372 5.12887 24.6538 6.26584 24.6538 7.66451C24.6538 9.06145 25.8355 10.2001 27.2894 10.2001H39.6008L37.6308 14.8693C36.0843 18.5697 32.3471 20.9614 28.1133 20.9614C23.8636 20.9493 20.0018 18.4171 18.5052 14.6665L13.5935 2.23967C13.2073 1.26216 12.2178 0.605286 11.1304 0.605286H2.95131C1.49914 0.605286 0.315674 1.74224 0.315674 3.14092C0.315674 4.53959 1.49736 5.67656 2.95131 5.67656H9.31345L13.5792 16.469C15.8411 22.1903 21.6801 26.0362 28.1098 26.0362C29.9624 26.0362 31.79 25.7207 33.5412 25.0985C37.6219 23.6496 40.8928 20.6148 42.5123 16.7724L45.9523 8.61949C46.2192 7.98861 46.2139 7.29881 45.9398 6.67487Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default WhiteLogoAvatar;
