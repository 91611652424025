import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Theme } from '@mui/material';
import { ArrayOfNItens } from '../../../../../../helpers/utils/array';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    gap: 8,
  },
  bar: {
    height: 5,
    borderRadius: 5,
    flex: 1,
  },
}));

type OrderIndicatorBarProps = {
  totalBars: number;
  currentBar: number;
};

const OrderIndicatorBar: React.FC<OrderIndicatorBarProps> = ({
  totalBars,
  currentBar,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {ArrayOfNItens(totalBars).map((index) => (
        <Box
          data-testid="order-indicator-bar-bar"
          className={classes.bar}
          key={index}
          style={{
            backgroundColor: index + 1 <= currentBar ? '#FFBE5C' : '#CACECC',
          }}
        />
      ))}
    </Box>
  );
};

export default OrderIndicatorBar;
