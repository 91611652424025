import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    color: '#636965',

    '& + &': {
      marginTop: '0.375rem',
    },
  },
  legend: {
    fontWeight: 500,
    fontSize: '0.875rem',
    lineHeight: '150%',
  },
  value: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '21px',
  },
}));

type LineItemProps = {
  legend: string;
  value?: number;
};

const LineItem: React.FC<LineItemProps> = ({ legend, value }) => {
  const classes = useStyles();

  const resolveValue = (valueToResolve?: number) => {
    if (!valueToResolve) {
      return 'Ilimitado';
    }

    const valueIsBiggerThenOne = valueToResolve > 1;

    return `${valueToResolve} ${valueIsBiggerThenOne ? 'dias' : 'dia'}`;
  };

  return (
    <Box className={classes.root}>
      <Typography data-testid="legend" className={classes.legend}>
        {legend}
      </Typography>
      <Typography data-testid="value" className={classes.value} variant="h3">
        {resolveValue(value)}
      </Typography>
    </Box>
  );
};

export default LineItem;
