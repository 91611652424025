import React from 'react';
import { makeStyles } from '@mui/styles';
import { alpha, Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import OrderIndicatorBar from '../OrderIndicatorBar';
import { KeyboardArrowRight, Stars } from '@mui/icons-material';
import LoyaltyInformationSkeleton from './components/LoyaltyInformationSkeleton';
import { useRouter } from 'next/router';
import { LoyaltyPlan } from '../../../../../../shared/models/loyaltyPlan';
import { toCurrencyValue } from '../../../../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  orderQuantity: {
    fontWeight: 600,
    fontSize: '0.875rem',
    lineHeight: '21px',
  },
  orderMinValue: {
    fontWeight: 500,
    fontSize: '0.75rem',
    lineHeight: '21px',
    color: '#7C837F',
  },
  starIcon: {
    fontSize: 24,
    color: '#FFBE5C',
    backgroundColor: alpha('#FFBE5C', 0.1),
    padding: '0.5rem',
    borderRadius: '0.25rem',
    boxSizing: 'content-box',
    marginRight: '0.75rem',
  },
}));

type LoyaltyInformationProps = {
  data: LoyaltyPlan;
  currentPoints?: number;
  singleComponentVariant?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
};

const LoyaltyInformation: React.FC<LoyaltyInformationProps> = ({
  data,
  currentPoints,
  singleComponentVariant,
  onClick,
  isLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { push } = useRouter();

  const handleClick = () => {
    if (isMobile) {
      push('/loyalty-info');
      return;
    }

    if (onClick) {
      onClick();
    }
  };

  if (isLoading) {
    return (
      <LoyaltyInformationSkeleton
        singleComponentVariant={singleComponentVariant}
        onClick={Boolean(onClick)}
      />
    );
  }

  return (
    <Box
      data-testid="loyalty-information-container"
      style={{ cursor: 'pointer' }}
      role="button"
      onClick={handleClick}
    >
      <Box
        style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}
      >
        {singleComponentVariant && (
          <Stars
            className={classes.starIcon}
            data-testid="loyalty-information-star-icon"
          />
        )}

        <Box>
          <Typography
            style={{ color: '#191A19' }}
            variant="h2"
            className={classes.orderQuantity}
          >
            Peça {data.orderQuantity} vezes e ganhe{' '}
            {toCurrencyValue(data.couponDiscountValue)}
          </Typography>
          <Typography
            style={{ color: '#7C837F' }}
            variant="h4"
            className={classes.orderMinValue}
          >
            Apenas pedidos acima de {toCurrencyValue(data.orderMinValue)}
          </Typography>
        </Box>
        {onClick && <KeyboardArrowRight style={{ marginLeft: '0.75rem' }} />}
      </Box>

      <OrderIndicatorBar
        currentBar={currentPoints ?? 0}
        totalBars={data.orderQuantity}
      />
    </Box>
  );
};

export default LoyaltyInformation;
