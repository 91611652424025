import React, { memo } from 'react';
import { Grid, Skeleton, Box } from '@mui/material';

const RandomNumberOfItensSmall: React.FC = ({ children }) => {
  return (
    <>
      {Array.from(Array(Math.floor(Math.random() * 4) + 6).keys()).map(
        (_, key) => (
          <Grid
            key={key}
            item
            xs={12}
            style={{
              borderBottom: '1px solid #CCCCCC',
              padding: '12px 8px',
              position: 'relative',
            }}
          >
            <Skeleton
              variant="rectangular"
              width={Math.random() * 100 + 50}
              height={24}
              style={{ borderRadius: 16 }}
            />
            {Math.floor(Math.random() * 10) < 6 && (
              <Skeleton
                variant="rectangular"
                width={Math.random() * 100 + 150}
                height={24}
                style={{ marginTop: 8, borderRadius: 8 }}
              />
            )}
            <Box
              style={{
                position: 'absolute',
                right: 8,
                bottom: '50%',
                transform: 'translateY(50%)',
              }}
            >
              <Skeleton variant="circular" width={24} height={24} />
            </Box>
          </Grid>
        )
      )}
    </>
  );
};

export default memo(RandomNumberOfItensSmall);
