import { CatalogSession } from '@wls-solucoes/lets-eat-types';
import Api from './api';

const baseUrl = '/api/public/sessions';

async function create(
  establishmentGuid: string,
  contactInformation: string
): Promise<CatalogSession> {
  return Api.post(
    `${baseUrl}/${establishmentGuid}/create`,
    { contactInformation },
    {
      headers: {
        'x-api-version': '3',
      },
    }
  ).then((response) => response.data);
}

async function update(
  establishmentGuid: string,
  data: CatalogSession
): Promise<CatalogSession> {
  return Api.put(`${baseUrl}/${establishmentGuid}/update`, data, {
    headers: {
      'x-api-version': '3',
    },
  }).then((response) => response.data);
}

async function get(
  establishmentGuid: string,
  sessionGuid: string
): Promise<CatalogSession> {
  return Api.get(
    `${baseUrl}/${establishmentGuid}/session/${sessionGuid.replace('-', '')}`,
    {
      headers: {
        hideErrorMessage: 'true',
        'x-api-version': '3',
      },
    }
  ).then((response) => response.data);
}

export const publicSessionService = {
  create,
  update,
  get,
};
