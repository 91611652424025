import { Box, Grid, Skeleton } from '@mui/material';
import React from 'react';
import RandomNumberOfItens from './components/RandomNumberOfItens';
import RandomNumberOfItensSmall from './components/RandomNumberOfItensSmall';

interface SkeletonLoadingProps {
  isXs: boolean;
}

const SkeletonLoading: React.FC<SkeletonLoadingProps> = ({ isXs }) => {
  return (
    <Box data-testid="loading-container">
      {isXs && (
        <>
          <Box
            data-testid="mobile-variant-indicator"
            style={{ display: 'flex' }}
          >
            <Skeleton
              variant="rectangular"
              width={29}
              height={29}
              style={{ marginRight: 8 }}
            />
            <Skeleton
              variant="rectangular"
              width={110}
              height={29}
              style={{ marginBottom: 8 }}
            />
          </Box>
          <Skeleton
            variant="rectangular"
            width={Math.random() * 350 + 100}
            height={20}
            style={{ marginBottom: 24 }}
          />
        </>
      )}
      <Grid container spacing={isXs ? 4 : 0} columns={12}>
        {isXs ? <RandomNumberOfItens /> : <RandomNumberOfItensSmall />}
      </Grid>
      {isXs && (
        <>
          <Skeleton
            variant="rectangular"
            width={210}
            height={29}
            style={{ marginBottom: 8, marginTop: 48 }}
          />
          <Skeleton
            variant="rectangular"
            width={Math.random() * 150 + 100}
            height={20}
            style={{ marginBottom: 24 }}
          />
        </>
      )}
      <Grid container spacing={isXs ? 4 : 0} columns={12}>
        {isXs ? <RandomNumberOfItens /> : <RandomNumberOfItensSmall />}
      </Grid>
    </Box>
  );
};

export default SkeletonLoading;
