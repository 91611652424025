import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { LoyaltyPlan } from '../../../../shared/models/loyaltyPlan';
import LoyaltyInfoContent from './components/LoyaltyInfoContent';

const useStyles = makeStyles<Theme>((theme) => ({
  paper: {
    width: '100%',
    maxWidth: 592,

    [theme.breakpoints.down('sm')]: {
      margin: 0,
      height: '100%',
      maxHeight: '100%',
    },
  },
  dialogTitle: {
    padding: '1.25rem 1rem',
    borderBottom: '1px solid #CACECC',
  },
  dialogContent: {
    padding: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0 1rem',
      marginTop: '1rem',
    },
  },
  dialogActions: {
    padding: '0.5rem 1.5rem 1.5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '0.5rem 1rem',
      borderTop: '1px solid #CACECC',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '1.125rem',
    lineHeight: '130%',
    color: '#121212',
    marginBottom: 8,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0,
    },
  },
}));

interface LoyaltyInfoDialogProps {
  open: boolean;
  onClose: () => void;
  data: LoyaltyPlan;
}

const LoyaltyInfoDialog: React.FC<LoyaltyInfoDialogProps> = ({
  open,
  onClose,
  data,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onBackdropClick={onClose}
      onClose={onClose}
      classes={{
        paper: classes.paper,
      }}
      data-testid="loyalty-info-dialog"
    >
      <DialogContent className={classes.dialogContent}>
        <Typography className={classes.title}>
          Programa de Fidelidade
        </Typography>
        <LoyaltyInfoContent data={data} />
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <Button
          data-testid="close-button"
          style={{ height: 48 }}
          color="primary"
          onClick={onClose}
          fullWidth
          variant="contained"
          disableElevation
        >
          entendi
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoyaltyInfoDialog;
