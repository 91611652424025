import { Box, Typography } from '@mui/material';
import React from 'react';
import {
  getCardFlags,
  getPaymentTypeProps,
} from '../../../../../../../../../../helpers/utils';
import {
  CardFlag,
  PaymentType,
} from '../../../../../../../../../../shared/models';
import PaymentCard from '../PaymentCard';

interface PaymentMethodSectionProps {
  paymentType: PaymentType;
  flags?: CardFlag[];
}

const PaymentMethodSection: React.FC<PaymentMethodSectionProps> = ({
  paymentType,
  flags,
}) => {
  const { name, icon } = getPaymentTypeProps(paymentType, '#666');

  return (
    <Box>
      <Typography style={{ fontWeight: 700 }}>{name}</Typography>

      <Box mt={2} display="flex" flexWrap="wrap" style={{ gap: 16 }}>
        {flags && flags.length > 0 ? (
          <>
            {flags.map((flag) => (
              <PaymentCard
                key={flag.guid}
                icon={getCardFlags(flag.name).icon ?? ''}
                label={flag.name}
              />
            ))}
          </>
        ) : (
          <PaymentCard icon={icon} label={name} />
        )}
      </Box>
    </Box>
  );
};

export default PaymentMethodSection;
