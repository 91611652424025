import { Box, Grid, Hidden, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CatalogCategory } from '@wls-solucoes/lets-eat-types';
import { useFormik } from 'formik';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/types';
import scoredSearch from '../../../../shared/services/scoredSearch';
import CatalogItens from './components/CatalogItens';
import Filters from './components/Filters';
import SearchBar from './components/SearchBar';

interface useStylesProps {
  filterHeight?: number;
}

const useStyles = makeStyles<Theme, useStylesProps>((theme) => ({
  root: {
    marginBottom: 60,
    paddingTop: ({ filterHeight }) => filterHeight,
  },
  filter: {
    padding: '32px 0',
  },
  filterGrid: {
    display: 'flex',
    gap: 32,
    width: '100%',
    maxWidth: 1216,
    margin: '0 auto',
  },
}));

export interface initialValuesProps {
  filtersIndex: number | false;
  catalogItens: CatalogCategory[];
  isLoading: boolean;
}

const initialValues: initialValuesProps = {
  filtersIndex: false,
  catalogItens: [],
  isLoading: false,
};

const Catalog: React.FC = () => {
  const refBoxFilter = useRef<any>(null);

  const classes = useStyles({
    filterHeight: refBoxFilter.current?.clientHeight,
  });

  const { search } = useSelector((state: RootState) => state.searchReducer);

  const formik = useFormik<initialValuesProps>({
    initialValues,
    onSubmit: (values) => {},
  });

  const { values } = formik;
  const catalogItensFiltered = useMemo(() => {
    const newValues = values.catalogItens.map((v) => ({
      ...v,
      items: scoredSearch({
        originalArr: v.items.map((item) => ({
          ...item,
          catalogName: v.name,
          catalogDescription: v.description,
        })),
        strSearch: search.toLowerCase(),
        fieldSearch: [
          'name',
          'description',
          'catalogName',
          'catalogDescription',
          'searchTags',
        ],
      }),
    }));

    return values.filtersIndex !== false
      ? [newValues[values.filtersIndex]]
      : newValues;
  }, [search, values.catalogItens, values.filtersIndex]);

  return (
    <Box className={classes.root}>
      <Hidden smDown>
        <Box className={classes.filter} ref={refBoxFilter}>
          <Grid container className={classes.filterGrid}>
            <SearchBar />
            <Filters formik={formik} />
          </Grid>
        </Box>
      </Hidden>
      <CatalogItens
        formik={formik}
        catalogItensFiltered={catalogItensFiltered as any}
      />
    </Box>
  );
};

export default Catalog;
