import React from 'react';
import { makeStyles } from '@mui/styles';
import { alpha, Box, IconButton, Skeleton } from '@mui/material';
import { KeyboardArrowRight, Stars } from '@mui/icons-material';
import { ArrayOfNItens } from '../../../../../../../../helpers/utils/array';

const useStyles = makeStyles((theme) => ({
  starIcon: {
    fontSize: 24,
    color: '#FFBE5C',
    backgroundColor: alpha('#FFBE5C', 0.1),
    padding: '0.5rem',
    borderRadius: '0.25rem',
    boxSizing: 'content-box',
    marginRight: '0.75rem',
  },
}));

type LoyaltyInformationSkeletonProps = {
  onClick?: boolean;
  singleComponentVariant?: boolean;
};

const LoyaltyInformationSkeleton: React.FC<LoyaltyInformationSkeletonProps> = ({
  onClick,
  singleComponentVariant,
}) => {
  const classes = useStyles();

  const getRandomArbitrary = (min: number, max: number) => {
    return Math.random() * (max - min) + min;
  };

  return (
    <Box data-testid="loyalty-information-skeleton-container">
      <Box
        style={{
          marginBottom: '1rem',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {singleComponentVariant && <Stars className={classes.starIcon} />}

        <Box>
          <Skeleton
            variant="text"
            animation="wave"
            width={getRandomArbitrary(200, 250)}
            height={20}
          />
          <Skeleton
            variant="text"
            animation="wave"
            width={getRandomArbitrary(150, 200)}
            height={20}
          />
        </Box>
        {onClick && (
          <IconButton size="small" disabled style={{ marginLeft: '0.75rem' }}>
            <KeyboardArrowRight />
          </IconButton>
        )}
      </Box>

      <Box display="flex" gap="8px">
        {ArrayOfNItens(Math.round(getRandomArbitrary(2, 5))).map((index) => (
          <Skeleton
            key={index}
            variant="text"
            animation="wave"
            height={10}
            style={{ flex: 1 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default LoyaltyInformationSkeleton;
