import { Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '10px 8px',
    border: 'none',
    backgroundColor: '#efefef',
  },
}));

interface PaymentCardProps {
  icon: string | JSX.Element;
  label: string;
}

const PaymentCard: React.FC<PaymentCardProps> = ({ icon, label }) => {
  const classes = useStyles();

  return (
    <Card variant="outlined" className={classes.root}>
      {typeof icon === 'string' ? (
        <img height={24} src={icon} alt={label} />
      ) : (
        icon
      )}

      <Typography style={{ color: '#666' }}>{label}</Typography>
    </Card>
  );
};

export default PaymentCard;
