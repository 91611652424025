import {
  Box,
  Collapse,
  Hidden,
  Theme,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import { CatalogSession } from '@wls-solucoes/lets-eat-types';
import type { NextPage } from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getDiscountCodeByCode } from '../../redux/reducers/discountCode/actions';
import { setCouponAwaitingAuth } from '../../redux/reducers/discountCode/reducer';
import { setEstablishmentData } from '../../redux/reducers/establishment/reducer';
import {
  OrderCheckoutResetNewItemAddedIndicator,
  setDiscountCode,
  setOrderMetadata,
  setOrderTrackingDetails,
} from '../../redux/reducers/order/reducer';
import { setSession } from '../../redux/reducers/session/reducer';
import { getDiscountCode } from '../../redux/reducers/userDiscountCode/actions';
import { setUserInfo } from '../../redux/reducers/userInfo/reducer';
import { RootState } from '../../redux/types';
import AvailableCouponDialogAlert from '../../shared/components/AvailableCouponDialogAlert';
import CouponAlert from '../../shared/components/CouponAlert';
import CouponWithRequiredAuthAlert from '../../shared/components/CouponWithRequiredAuthAlert';
import { Coupon, Establishment } from '../../shared/models';
import { authService } from '../../shared/services/authService';
import { publicSessionService } from '../../shared/services/publicSession';
import { useCouponWithRequiredAuthStore } from '../../zustand/couponWithRequiredAuth';
import { useReadOnly } from '../../zustand/readOnly';
import CartWithItemsSpeechBubble from './components/CartWithItemsSpeechBubble';
import Catalog from './components/Catalog';
import EstablishmentBasicInfoSection from './components/EstablishmentBasicInfoSection';
import MobileCartButton from './components/MobileCartButton';

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    minHeight: 'calc(100vh - 129px)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 184px)',
    },
  },

  container: {
    maxWidth: 1216,
    width: 1216,
  },
}));

interface HomeProps {
  establishmentData?: Establishment;
  disc?: {
    error: string | null;
    Coupon: Coupon;
    couponCode: string | null;
    errorName: string | null;
  };
}

const Home: NextPage<HomeProps> = ({ establishmentData, disc }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { query, replace, pathname } = useRouter();

  const {
    onOpen: handleOpenCouponWithRequiredAuth,
    onClose: handleCloseCouponWithRequiredAuth,
    isOpen: showCouponWithRequiredAuth,
    onCloseAllCallback,
  } = useCouponWithRequiredAuthStore();

  const { setReadOnly, isReadOnly } = useReadOnly();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { search } = useSelector((state: RootState) => state.searchReducer);
  const { discountCode } = useSelector(
    (state: RootState) => state.userDiscountReducer
  );
  const { couponAwaitingAuth } = useSelector(
    (state: RootState) => state.discountCodeReducer
  );
  const { user } = useSelector((state: RootState) => state.publicUserReducer);
  const { newItemAdded, order, orderMetadata } = useSelector(
    (state: RootState) => state.orderReducer
  );
  const { userInfo } = useSelector((state: RootState) => state.userInfoReducer);
  const { session } = useSelector((state: RootState) => state.sessionReducer);

  const [showCartWithItemsSpeechBubble, setShowCartWithItemsSpeechBubble] =
    useState(false);
  const [showCouponAlert, setShowCouponAlert] = useState({
    isOpen: false,
    isError: false,
  });

  const handleOpenCouponErrorAlert = () => {
    setShowCouponAlert({
      isOpen: true,
      isError: true,
    });
  };

  const handleOpenCouponAlert = () => {
    setShowCouponAlert({
      isOpen: true,
      isError: false,
    });
  };

  const handleCloseCouponAlert = () => {
    setShowCouponAlert({
      isOpen: false,
      isError: false,
    });
    dispatch(setCouponAwaitingAuth(undefined));
  };

  const getCoupon = (code: string) => {
    const establishmentGuid =
      establishmentData?.guid ?? establishment?.guid ?? '';

    dispatch(
      getDiscountCodeByCode({
        Code: code,
        establishmentGuid,
        hideErrorMessage: true,
      }) as any
    )
      .then(unwrapResult)
      .then((coupon: any) => {
        if (coupon.couponNotFound) {
          handleOpenCouponErrorAlert();
          return;
        }
        dispatch(setDiscountCode(coupon));

        handleOpenCouponAlert();
      })
      .finally(() => {
        (window as any).isFetching = false;
      });
  };

  window.onbeforeunload = () => {
    if ((order.items?.length ?? 0) > 0) {
      return 'Are you sure you want to leave?';
    }
  };

  useEffect(() => {
    if (establishmentData) dispatch(setEstablishmentData(establishmentData));

    const metadataQuery = { ...query };

    if (query && !orderMetadata?.query) {
      dispatch(setOrderMetadata({ query: metadataQuery }));
    }

    if (user && couponAwaitingAuth) {
      if ((window as any).isSecondLoad) {
        getCoupon(couponAwaitingAuth);
      } else {
        (window as any).isSecondLoad = true;
      }
    }

    setReadOnly(query.readOnly === 'true');
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!disc || (window as any).isCouponValidated) return;

    (window as any).isCouponValidated = true;

    if (disc.error) {
      if (disc.errorName === 'DiscountCodeNeedsPublicUserException') {
        if (user && disc.couponCode) {
          getCoupon(disc.couponCode);

          return;
        }

        dispatch(setCouponAwaitingAuth(disc.couponCode));
        handleOpenCouponWithRequiredAuth();

        return;
      }

      toast.error(disc.error);
    } else if (disc.Coupon) {
      dispatch(setDiscountCode(disc.Coupon));
      handleOpenCouponAlert();
    }
  }, [disc]);

  useEffect(() => {
    const accessToken = authService.getAccessToken();

    if (establishmentData && !discountCode && accessToken) {
      dispatch(
        getDiscountCode({
          establishmentGuid: establishmentData.guid,
          pageIndex: 1,
        })
      );
    }
  }, [establishmentData]);

  useEffect(() => {
    if (!establishment) return;

    const handleCreateNewSession = async () => {
      if (!userInfo) return;

      const newSession = await publicSessionService.create(
        establishment.guid,
        userInfo.whatsApp
      );

      dispatch(setSession(newSession));

      dispatch(
        setOrderTrackingDetails({
          publicUserSession: newSession.guid,
        })
      );
    };

    (async () => {
      if (!query.ccs && !session && userInfo) {
        await handleCreateNewSession();

        return;
      }

      if (
        query.ccs &&
        (!session ||
          (session as CatalogSession).guid.replace(/\W/g, '') !== query.ccs)
      ) {
        try {
          const res = await publicSessionService.get(
            establishment.guid,
            query.ccs as string
          );

          if (user && userInfo?.whatsApp) {
            if (userInfo.whatsApp === res.contactInformation) {
              dispatch(setSession(res));

              dispatch(
                setOrderTrackingDetails({
                  publicUserSession: res.guid,
                })
              );

              return;
            }

            await handleCreateNewSession();

            return;
          }

          dispatch(
            setOrderTrackingDetails({
              publicUserSession: res.guid,
            })
          );

          dispatch(setSession(res));
          dispatch(setUserInfo({ whatsApp: res.contactInformation }));
        } catch {
          await handleCreateNewSession();

          const url = query;
          delete url.ccs;

          const stringified = `?${queryString.stringify(url)}`;
          replace(`/${stringified}`, undefined, {
            shallow: true,
          });
        }
      }
    })();
  }, [establishment]); // eslint-disable-line

  useEffect(() => {
    if (!showCartWithItemsSpeechBubble && newItemAdded) {
      setShowCartWithItemsSpeechBubble(true);
    }

    const timer = setTimeout(() => {
      setShowCartWithItemsSpeechBubble(false);
      dispatch(OrderCheckoutResetNewItemAddedIndicator());
    }, 1000 * 3);

    return () => clearTimeout(timer);
  }, [newItemAdded]); // eslint-disable-line

  if (establishment) {
    return (
      <>
        <Head>
          <title>{`${establishment?.name} - Cardápio e Delivery`}</title>
        </Head>

        <CouponWithRequiredAuthAlert
          open={showCouponWithRequiredAuth}
          onClose={handleCloseCouponWithRequiredAuth}
          closeAll={() => {
            handleCloseCouponWithRequiredAuth();
            onCloseAllCallback?.();
          }}
        />

        <AvailableCouponDialogAlert dialogId="cart-coupon-dialog-cart" />

        <CouponAlert
          open={showCouponAlert.isOpen}
          onClose={handleCloseCouponAlert}
          isError={showCouponAlert.isError}
        />

        <CartWithItemsSpeechBubble show={showCartWithItemsSpeechBubble} />

        <Box className={classes.root}>
          <Box className={classes.container}>
            <Collapse in={!isMobile || (isMobile && !search)}>
              <EstablishmentBasicInfoSection />
            </Collapse>

            <Catalog />
          </Box>
        </Box>

        <Hidden smUp>{!isReadOnly && <MobileCartButton />}</Hidden>
      </>
    );
  }

  return <h1>sem loja</h1>;
};

export default Home;
