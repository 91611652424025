import React from 'react';

interface SpeechBubbleTriangleProps {
  color: string;
}

const SpeechBubbleTriangle: React.FC<SpeechBubbleTriangleProps> = ({
  color,
}) => {
  return (
    <svg
      width="21"
      height="17"
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2583 16.5C10.8734 17.1667 9.91118 17.1667 9.52628 16.5L0.866025 1.5C0.481125 0.833333 0.96225 8.41226e-08 1.73205 1.51421e-07L19.0526 1.66563e-06C19.8224 1.73293e-06 20.3035 0.833335 19.9186 1.5L11.2583 16.5Z"
        fill={color}
      />
    </svg>
  );
};

export default SpeechBubbleTriangle;
