import { Close, ReportProblem } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grow,
  Hidden,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import TicketPercentOutline from '../../../modules/assets/TicketPercentOutline';
import { RootState } from '../../../redux/types';

const DialogPaper = styled(Paper)(({ theme }) => ({
  width: 500,
  margin: '8px !important',
  overflowX: 'hidden',
  overflowY: 'hidden !important' as any,
  borderRadius: 8,

  [theme.breakpoints.up('sm')]: {
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: -320,
      left: -270,
      width: 490,
      height: 400,
      borderRadius: '50%',
      backgroundColor: alpha(theme.palette.primary.main, 0.15),
      zIndex: 0,
    },
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: -365,
    right: -105,
    width: 490,
    height: 400,
    transform: 'rotate(135deg)',
    borderRadius: '50%',
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
    zIndex: 0,

    [theme.breakpoints.down('sm')]: {
      right: -275,
      top: -335,
      transform: 'rotate(0deg)',
    },
  },

  '& .MuiDialogContent-root': {
    padding: '40px 16px 12px',
    zIndex: 1,
  },

  '& .MuiDialogActions-root': {
    padding: '12px 16px 24px',
    zIndex: 1,
    gap: 8,

    button: {
      padding: '8px 24px',
    },

    [theme.breakpoints.down('sm')]: {
      button: {
        width: '100%',
      },
    },
  },
}));

const CloseButton = styled(IconButton)(() => ({
  position: 'absolute',
  top: 8,
  right: 8,
  zIndex: 2,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  fontWeight: 700,

  span: {
    color: theme.palette.primary.main,
  },
}));

const Text = styled(Typography)(({ theme }) => ({
  fontSize: '1.125rem',
  textAlign: 'center',

  span: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
}));

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Grow ref={ref} {...props} />;
});

interface CouponAlertProps {
  open: boolean;
  onClose: () => void;
  isError: boolean;
}

const CouponAlert: React.FC<CouponAlertProps> = ({
  open,
  onClose,
  isError,
}) => {
  const theme = useTheme();
  const { user } = useSelector((state: RootState) => state.publicUserReducer);

  const datas = useMemo(() => {
    if (isError) {
      return {
        icon: <ReportProblem color="error" style={{ fontSize: 20 }} />,
        title: (
          <Title variant="h6" style={{ fontSize: '1.125rem', fontWeight: 700 }}>
            CUPOM{' '}
            <span style={{ color: theme.palette.error.main }}>INVÁLIDO</span>
          </Title>
        ),
        text: (
          <Text>
            {user ? (
              <>
                Olá <span>{user?.name}</span>
                <strong>, não foi possível</strong>
              </>
            ) : (
              <strong>Não foi possível</strong>
            )}{' '}
            aplicar esse cupom O cupom está <strong>inválido</strong> ou{' '}
            <strong>expirado</strong>.
            <span role="img" aria-label="ícone de cara triste">
              ☹️
            </span>
          </Text>
        ),
      };
    }

    return {
      icon: <TicketPercentOutline color="primary" style={{ fontSize: 20 }} />,
      title: (
        <Title variant="h6" style={{ fontSize: '1.125rem', fontWeight: 700 }}>
          CUPOM <span>APLICADO</span>
        </Title>
      ),
      text: user ? (
        <Text>
          Olá <span>{user?.name}</span>, seu cupom{' '}
          <strong>foi aplicado com sucesso!</strong>{' '}
          <span role="img" aria-label="ícone de festa">
            🎉
          </span>
        </Text>
      ) : (
        <Text>
          Seu cupom <strong>foi aplicado com sucesso!</strong>{' '}
          <span role="img" aria-label="ícone de festa">
            🎉
          </span>
        </Text>
      ),
    };
  }, [isError, user]); // eslint-disable-line

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      PaperComponent={DialogPaper}
      onClose={onClose}
    >
      <Tooltip arrow title="Fechar">
        <CloseButton data-testid="close-button" onClick={onClose}>
          <Close />
        </CloseButton>
      </Tooltip>

      <DialogContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
        >
          {datas.icon}
          {datas.title}
        </Stack>

        {datas.text}
      </DialogContent>

      <DialogActions disableSpacing>
        <Hidden smDown>
          <Button variant="outlined" onClick={onClose}>
            fechar
          </Button>
        </Hidden>

        <Button disableElevation variant="contained" onClick={onClose}>
          continuar pedido
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CouponAlert;
