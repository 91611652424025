import { LocalOffer } from '@mui/icons-material';
import {
  Box,
  Collapse,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { green, grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/system';
import {
  CatalogCategory,
  CatalogItem,
  CategoryType,
  ItemDisplayModeInCategory,
} from '@wls-solucoes/lets-eat-types';
import { FormikProps } from 'formik';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Element, scroller } from 'react-scroll';
import { TransitionGroup } from 'react-transition-group';
import BoredCat from '../../../../../../modules/assets/bored-cat';
import {
  removeItemDetails,
  removeItemToEditInfo,
  removeRecommendationGuid,
  setCatalog,
  setIsLoadingItemDetails,
  setItemDetails,
  setItemRecommendationData,
  setRecommendationGuid,
} from '../../../../../../redux/reducers/catalog/reducer';
import { removeItemsByGuid } from '../../../../../../redux/reducers/order/reducer';
import { removeRecommendation } from '../../../../../../redux/reducers/recommendation/reducer';
import { cleanSearch } from '../../../../../../redux/reducers/search/reducer';
import { RootState } from '../../../../../../redux/types';
import BackdropProgressIndicator from '../../../../../../shared/components/BackdropProgressIndicator';
import NoItemsFound from '../../../../../../shared/components/NoItemsFound';
import { ItemRecommendation } from '../../../../../../shared/models';
import { establishmentService } from '../../../../../../shared/services/establishment';
import { initialValuesProps } from '../../Catalog';
import CatalogIten from './components/CatalogIten';
import ItemRecommendationDialog from './components/ItemRecommendationDialog';
import OrderCategoryAccordion from './components/OrderCategoryAccordion';
import Promotions from './components/Promotions';
import SkeletonLoading from './components/SkeletonLoading';

const OrderItemDialog = dynamic(() => import('./components/OrderItemDialog'), {
  loading: () => <BackdropProgressIndicator />,
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 8,
  },

  category: {
    '& + &': {
      marginTop: 48,
    },
  },

  title: {
    fontSize: 24,
    fontWeight: 600,
    lineHeight: '29px',
  },

  description: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: '20px',
    color: grey[600],
    marginBottom: 24,
    wordBreak: 'break-word',
  },

  catalogItens: {
    display: 'flex',
    gap: 32,
    flexWrap: 'wrap',
  },
}));

type CatalogItensProps = {
  formik: FormikProps<initialValuesProps>;
  catalogItensFiltered: CatalogCategory[];
};

export interface DiscountItem {
  product: CatalogItem;
  catalogItemGuid: string;
  catalogItemType: CategoryType;
}

const CatalogItens: React.FC<CatalogItensProps> = ({
  formik,
  catalogItensFiltered,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { push } = useRouter();

  const { values, setFieldValue } = formik;
  const isXs = useMediaQuery('(min-width:600px)');

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );
  const { search } = useSelector((state: RootState) => state.searchReducer);
  const { catalog, itemDetails, isLoadingItemDetails, itemRecommendationData } =
    useSelector((state: RootState) => state.catalogReducer);
  const { order } = useSelector((state: RootState) => state.orderReducer);
  const { recommendations } = useSelector(
    (state: RootState) => state.recommendationReducer
  );

  const [promotions, setPromotions] = useState<DiscountItem[]>([]);

  const handleGetCatalogItens = (refetch = false) => {
    if (establishment?.guid) {
      if (!catalog || refetch) {
        setFieldValue('isLoading', true);

        establishmentService
          .getEstablishmentCatalog(establishment.guid)
          .then((v) => {
            const updatedValues = v.map((category) => {
              const updatedCategory = {
                ...category,
                expanded:
                  establishment?.webSettings.itemDisplayModeInCategory ===
                  ItemDisplayModeInCategory.open,
              };

              return updatedCategory;
            });

            setFieldValue('catalogItens', updatedValues);
            setFieldValue('isLoading', false);
            dispatch(setCatalog(updatedValues));
          });
      } else {
        setFieldValue('catalogItens', catalog);
      }
    }
  };

  const handleSelectItem = (itemGuid: string, categoryType?: CategoryType) => {
    dispatch(setIsLoadingItemDetails(true));

    establishmentService
      .getItemDetails(establishment?.guid ?? '', itemGuid, categoryType)
      .then((item) => {
        dispatch(setItemDetails(item));

        if (!isXs) {
          push({
            pathname: `/item-details/${itemGuid}`,
            query: { type: item.type },
          });
        }
      })
      .catch(async () => {
        const Swal = (await import('sweetalert2')).default;

        const item = catalog
          ?.find((category) =>
            category.items.some((item) => item.guid === itemGuid)
          )
          ?.items.find((item) => item.guid === itemGuid);

        const hasItemsInCart = order?.items?.some(
          (item) => item.guid === itemGuid
        );

        if (hasItemsInCart) {
          dispatch(removeItemsByGuid(itemGuid));
        }

        if (recommendations?.some((item) => item.itemGuid === itemGuid)) {
          dispatch(removeRecommendation(itemGuid));
        }

        handleGetCatalogItens(true);

        Swal.fire({
          title: 'Ops!',
          text: `O produto ${item?.name} não está disponível no momento${
            hasItemsInCart ? `, as unidades em seu pedido foram REMOVIDAS` : ''
          }.`,
          icon: 'error',
        });
      })
      .finally(() => dispatch(setIsLoadingItemDetails(false)));
  };

  const handleShowRecommendation = (itemRecommendation: ItemRecommendation) => {
    dispatch(setItemRecommendationData(itemRecommendation));

    dispatch(setRecommendationGuid(itemDetails?.guid));
  };

  const handleIWantClick = (): void => {
    handleSelectItem(itemRecommendationData?.itemGuid ?? '');

    dispatch(setItemRecommendationData(undefined));
  };

  useEffect(handleGetCatalogItens, [establishment]);

  useEffect(() => {
    if (
      catalog &&
      JSON.stringify(catalog) !== JSON.stringify(values.catalogItens)
    ) {
      setFieldValue('catalogItens', catalog);
    }
  }, [catalog]);

  useEffect(() => {
    const discountItens: DiscountItem[] = [];
    catalogItensFiltered.map((catalogItem) => {
      if (catalogItem.isOnOpeningHours) {
        return catalogItem.items.map((item) => {
          if (item.promotionPercentage) {
            discountItens.push({
              product: item,
              catalogItemGuid: catalogItem.guid ?? '',
              catalogItemType: catalogItem.categoryType,
            });
          }
        });
      }

      return;
    });
    setPromotions(discountItens);
  }, [catalogItensFiltered]); // eslint-disable-line

  useEffect(() => {
    if (!isXs && search) {
      scroller.scrollTo('catalog-items', {
        smooth: true,
      });
    }
  }, [search]); // eslint-disable-line

  return (
    <>
      <ItemRecommendationDialog
        open={itemRecommendationData !== undefined}
        recommendedItem={itemRecommendationData}
        onIWantClick={handleIWantClick}
        onClose={() => {
          dispatch(setItemRecommendationData(undefined));
          dispatch(removeRecommendationGuid());
        }}
      />

      {itemDetails && isXs ? (
        <OrderItemDialog
          categoryGuid={itemDetails.categoryGuid}
          item={itemDetails}
          handleShowRecommendation={handleShowRecommendation}
          onClose={() => {
            dispatch(removeItemToEditInfo());
            dispatch(removeItemDetails());
          }}
        />
      ) : null}

      {isLoadingItemDetails && <BackdropProgressIndicator />}

      <Box className={classes.root}>
        <Element name="catalog-items">
          {isXs ? (
            <TransitionGroup>
              {catalogItensFiltered
                .filter((i) => i.items.length > 0)
                .map((catalogItem) => (
                  <Collapse key={catalogItem.guid} className={classes.category}>
                    <Box>
                      <Box style={{ display: 'flex', marginBottom: 8 }}>
                        {catalogItem.items.some(
                          (v) => v.promotionPercentage !== undefined
                        ) && (
                          <Box
                            data-testid="category-promotion-indicator"
                            style={{
                              background: 'rgba(233, 245, 236, 0.75)',
                              padding: 4,
                              marginRight: 8,
                              borderRadius: 4,
                              maxHeight: 28,
                            }}
                          >
                            <LocalOffer
                              style={{
                                color: green[600],
                                fontSize: 20,
                              }}
                            />
                          </Box>
                        )}
                        <Typography
                          className={classes.title}
                          style={
                            !catalogItem.isOnOpeningHours
                              ? { opacity: 0.5, textDecoration: 'line-through' }
                              : undefined
                          }
                        >
                          {catalogItem.name}
                        </Typography>
                      </Box>
                      <Typography
                        className={classes.description}
                        style={
                          !catalogItem.isOnOpeningHours
                            ? { opacity: 0.5, textDecoration: 'line-through' }
                            : undefined
                        }
                      >
                        {catalogItem.description}
                      </Typography>
                      <Grid container spacing={4} columns={18}>
                        {catalogItem.items.map((item) => (
                          <Grid item sm={9} lg={6} key={item.guid}>
                            <CatalogIten
                              item={item}
                              isOnOpeningHours={catalogItem.isOnOpeningHours}
                              categoryGuid={catalogItem.guid ?? ''}
                              handleSelectItem={(itemGuid) =>
                                handleSelectItem(
                                  itemGuid,
                                  catalogItem.categoryType
                                )
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Box>
                  </Collapse>
                ))}
            </TransitionGroup>
          ) : (
            <>
              {promotions.length > 0 && (
                <Promotions
                  items={promotions}
                  handleSelectItem={handleSelectItem}
                />
              )}
              {catalogItensFiltered
                .filter((i) => i.items.length > 0)
                .map((catalogItem) => (
                  <OrderCategoryAccordion
                    key={catalogItem.guid}
                    category={catalogItem}
                    handleSelectItem={handleSelectItem}
                  />
                ))}
            </>
          )}
        </Element>

        {values.isLoading ? (
          <SkeletonLoading isXs={isXs} />
        ) : (
          <>
            {values.catalogItens.length === 0 && (
              <Stack alignItems="center">
                <Stack width="100%" maxWidth={280} gap={3}>
                  <BoredCat />

                  <Typography
                    style={{
                      fontSize: '1.125rem',
                      textAlign: 'center',
                    }}
                  >
                    Este estabelecimento não possui itens disponíveis no momento
                  </Typography>
                </Stack>
              </Stack>
            )}
          </>
        )}

        {values.catalogItens.length !== 0 &&
          catalogItensFiltered.every((i) => i.items.length === 0) && (
            <NoItemsFound
              text="Não encontramos nenhum produto."
              resetFilterValues={() => {
                dispatch(cleanSearch());
              }}
            />
          )}
      </Box>
    </>
  );
};

export default CatalogItens;
