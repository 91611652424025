import { LocalMall, Room, SportsMotorsports } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { toCurrencyValue } from '../../../../../../../../helpers/utils';
import { RootState } from '../../../../../../../../redux/types';
import { DeliveryFeeType } from '../../../../../../../../shared/models';

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: 600,
    marginBottom: 10,
  },
}));

const AboutTab: React.FC = () => {
  const classes = useStyles();

  const { establishment } = useSelector(
    (state: RootState) => state.establishmentReducer
  );

  const address = establishment?.baseInfo.address;

  return (
    <Grid container>
      <Grid item xs={12} style={{marginBottom: 32}}>
        <Typography className={classes.title}>{establishment?.name}</Typography>

        <Box
          display="flex"
          alignItems="center"
          style={{ color: '#808080', gap: 8 }}
        >
          <Room />

          <Box data-testid="establishment-address-container">
            {establishment?.settings.showAddress && (
              <Typography>
                {`Rua ${address?.street}, ${address?.number} ${
                  address?.complement ? `- ${address.complement}` : ''
                }`}
              </Typography>
            )}
            <Typography>
              {`${address?.neighborhood}, ${address?.city.name} - ${address?.city.state.uf}`}
            </Typography>
          </Box>
        </Box>
      </Grid>

      {establishment?.baseInfo.about && (
        <Grid item xs={12} style={{marginBottom: 32}}>
          <Typography className={classes.title}>Sobre</Typography>

          <Typography style={{ color: '#808080' }}>
            {establishment?.baseInfo.about}
          </Typography>
        </Grid>
      )}

      <Grid item xs={12} style={{marginBottom: 32}}>
        <Typography className={classes.title}>Formas de entrega</Typography>

        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          justifyContent="space-between"
          style={{ gap: 16 }}
        >
          {establishment?.deliverySettings?.enableTakeOut && (
            <Box
              display="flex"
              alignItems="center"
              style={{ color: '#333', gap: 8 }}
            >
              <LocalMall />

              <Box>
                <Typography style={{ fontWeight: 600 }}>Retirada</Typography>
                <Typography
                  style={{
                    color: '#808080',
                    fontSize: '.875rem',
                    fontWeight: 400,
                  }}
                >
                  Retirar produtos no estabelecimento
                </Typography>
              </Box>
            </Box>
          )}

          {establishment?.deliverySettings?.enableDelivery && (
            <Box
              display="flex"
              alignItems="center"
              style={{ color: '#333', gap: 8 }}
            >
              <SportsMotorsports />

              <Box>
                <Typography style={{ fontWeight: 600 }}>Delivery</Typography>
                <Typography
                  style={{
                    color: '#808080',
                    fontSize: '.875rem',
                    fontWeight: 400,
                  }}
                >
                  Entrega no endereço informado
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Grid>

      {establishment?.deliverySettings?.deliveryFeeType ===
        DeliveryFeeType.neighborhoodFee && (
        <Grid item xs={12}>
          <Typography className={classes.title}>Entregas e valores</Typography>

          <Grid container style={{ gap: 16, marginTop: 16 }}>
            {establishment?.deliverySettings?.neighborhoodFees?.map(
              (fee, index) => (
                <Grid
                  item
                  xs={12}
                  display="flex"
                  flexDirection="column"
                  style={{ gap: 8 }}
                  key={`${fee.city.name}-${index}`}
                >
                  <Box display="flex">
                    <Room style={{ color: '#636965', marginRight: 8 }} />
                    <Typography style={{ color: '#636965', fontWeight: 600 }}>
                      {fee.city.name}
                    </Typography>
                  </Box>

                  {fee.neighborhoodFees.map((neighborhoodFee) => (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      key={neighborhoodFee.guid}
                    >
                      <Typography style={{ color: '#636965', fontWeight: 400 }}>
                        {neighborhoodFee.neighborhood}
                      </Typography>
                      <Typography style={{ color: '#636965', fontWeight: 400 }}>
                        {toCurrencyValue(neighborhoodFee.fee)}
                      </Typography>
                    </Box>
                  ))}
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default AboutTab;
